<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New File</span>
          <span v-if="modeType=='edit'">Edit File</span>
        </span>
        <span v-if="modeType=='edit'"><span class="font-weight-black">Name of File</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Label"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>

            <v-card
              elevation="0"
              class="d-flex align-center justify-center"
              height="200"
              style="border: 1px dashed rgba(0,0,0,0.2);"
            >
              <v-icon color="lightGrey">mdi-cloud-upload</v-icon>
              <span class="lightGrey--text d-flex ml-4 text-caption text-uppercase">Click or drag/drop to add file</span>
            </v-card>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span v-if="modeType=='create'">Add</span>
          <span v-if="modeType=='edit'">Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingUploadsCreateUpdate',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
