<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Meetings' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meetings
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Uploads from <span class="font-weight-black">Meeting Name</span></span>
            <p class="text-body-1">for Board Name</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add File
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="6">
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="uploadsSample"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.name="{ item }">
              <span class="d-flex py-4 flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.original }}
                </span>
              </span>
            </template>
            <template v-slot:item.actions>
              <div class="d-flex flex-row justify-center">

                <v-tooltip
                  top
                  v-for="(itemMenu, index) in itemsMenu"
                  :key="index"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal(itemMenu.actions,itemMenu.optional)"
                    >
                      <v-icon
                        size="20"
                        :color="itemMenu.type? itemMenu.type:'darkGrey'"
                      >
                        {{ itemMenu.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                      {{ itemMenu.text }}
                    </span>
                </v-tooltip>

              </div>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <meeting-uploads-create-update v-model="modals.createUpdate" :modeType="modeType"></meeting-uploads-create-update>
    <meeting-uploads-delete v-model="modals.delete"></meeting-uploads-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import MeetingUploadsCreateUpdate from '@/components/Meetings/MeetingUploadsCreateUpdate'
import MeetingUploadsDelete from '@/components/Meetings/MeetingUploadsDelete'

export default Vue.extend({
  name: 'MeetingUploads',
  components: {
    Portal,
    MeetingUploadsCreateUpdate,
    MeetingUploadsDelete
  },
  data: () => ({
    modeType: '',
    modals: {
      createUpdate: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: false,
        href: ''
      },
      {
        text: 'Uploads from Meeting Name',
        disabled: true,
        href: '/boards'
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-download', text: 'Download', actions: 'download' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Label / Original File Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'Upload by', value: 'owner' },
      { text: 'Date', value: 'date' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    uploadsSample: [
      {
        name: 'Name of the Uploaded File 1',
        original: 'original-file-name-1',
        type: 'DOCX',
        owner: 'Charles Simon',
        date: 'Nov 2nd 2020'
      },
      {
        name: 'Name of the Uploaded File 2',
        original: 'original-file-name-to-show-in-the-list-2',
        type: 'DOCX',
        owner: 'Charles Simon',
        date: 'Nov 2nd 2020'
      },
      {
        name: 'Name of the Uploaded File 3',
        original: 'original-3',
        type: 'PDF',
        owner: 'Rico Reis',
        date: 'Nov 2nd 2020'
      },
      {
        name: 'Name of the Uploaded File 4',
        original: 'original-file-name-to-show-in-the-list-4',
        type: 'XLS',
        owner: 'Vee Caron',
        date: 'Nov 2nd 2020'
      }
    ]
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    }
  }
})
</script>
